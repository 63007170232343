<template>
    <div class="suche">
      <img class="close-suche" src="../assets/tipp/tipp-cross.svg" @click="$router.go(-1)">
      <LanguageSwitch class="lang-switch"></LanguageSwitch>
      <h3>{{ $t('suche.title') }}</h3>
      <p>{{ $t('suche.text') }}</p>
      <form @submit.prevent="search" action="" method="get" class="search-form">
        <input type="text" :placeholder="$t('suche.placeholder')" v-model="searchTerm">
        <div class="line"></div>
        <button type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z"/></svg>
          </button>
      </form>
    </div>
</template>

<script>
    import LanguageSwitch from "@/components/LanguageSwitch";

    export default {
      name: "suche",
      components: {
        LanguageSwitch
      },
      data() {
        return {
          searchTerm: ''
        }
      },
      methods: {
        search() {
          if (this.searchTerm == '') {
            alert('Bitte einen Suchbegriff eingeben!');
            return false
          }
          //https://cse.google.com/cse?cx=009756306759678983301:pwynmmtk356#gsc.tab=0&gsc.q=test&gsc.sort=
          this.openInNewTab('https://cse.google.com/cse?cx=009756306759678983301:pwynmmtk356#gsc.tab=0&gsc.q=' + this.searchTerm + '&gsc.sort=')
          this.searchTerm = ''
        },
        openInNewTab(url) {
                var win = window.open(url, '_blank');
                win.focus();
            }
      },
    }
</script>

<style lang="scss" scoped>
.lang-switch {
  position: absolute;
  top: 0;
  right: 10%;
}

.suche {
  width: 100%;
  min-height: 90vh;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 70px;

  @include tablet-down {
    padding: 20px
  }

  h3 {
    font-family: "HKSentiments-black", sans-serif;
    font-size: 30px;
    width: 80%;
    margin-bottom: 0;
    padding-bottom: 0;

    @include tablet-down {
      width: 100%;
    }
  }

  p {
    font-family: "HKSentiments-bold", sans-serif;
    font-size: 22px;
    width: 80%;
    padding-bottom: 40px;

    @include tablet-down {
      width: 100%;
    }
  }
}

.close-suche {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  cursor: pointer;

  @include mobile-down {
    width: 35px;
  }
}

.search-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include tablet-down {
    width: 100%;
  }

  input[type="text"] {
    font-size: 30px;
    padding: 10px;
    border: none;
    border-bottom: black solid 4px;
    text-align: left;

    @include tablet-down {
      width: 95%;
    }
  }

  button {
    font-size: 25px;
    padding: 12px;
    padding-left: 60px;
    padding-right: 60px;
    cursor: pointer;
    margin-top: 20px;
    border: none;
    color: white;
    background-color: #BF99B3;

    &:hover {
      background-color: #D4AEC9;
    }
  }

  .line {
    padding: 10px;
  }
}

.tab-open {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

</style>
